import { useState } from 'react';
import tw from 'twin.macro';

import axios from 'axios';
import { Loader, WandSparkles } from 'lucide-react';

import HighlightContainer from '@/components/HighlightContainer';
import { Button } from '@/components/base';
import { Firebase } from '@/libs';
import { ProcessTranscribedTextData } from '@/libs/graphql';

const API_URL = import.meta.env.VITE_GEN_API_URL || 'http://localhost:5000/genai';

export interface TranscribeContainerProps {
  jobRole: string;
  jobDescriptions?: string;
  question: string;
  transcribedText: string;
  transcribedData?: ProcessTranscribedTextData[] | null;
}

interface TextHighlight {
  sentence: string;
  reason: string;
}

const SYSTEM_PROMPT = `Act as professional recruiter, you read a transcribed text from video interview. And you will highlight sentences that would related to the job. The transcribed text may have incorrect word/text due to the software (you may assume the meaning if the sentences does not make any senses)

WHAT YOU MUST DO:
* Provide sentences in exact word by word. DO NOT MODIFY any of them
* Provide answers with JSON data 
[ 
   { "sentence": "Excerpt from transcribe", "reason": "reason for highlight" }, 
]
`;

const useChat = () => {
  const [loading, setLoading] = useState(false);
  const generateChat = async (
    role: string,
    jobDescriptions: string | null,
    question: string,
    transcribeText: string,
  ) => {
    try {
      setLoading(true);
      const userMessage = `Job Title: ${role}
    ${
      jobDescriptions
        ? `Job Description: 
${jobDescriptions}`
        : ''
    }

    Question: ${question}

    Transcribed Text: ${transcribeText}
    `;
      const body = {
        messages: [
          {
            role: 'system',
            name: 'system',
            content: SYSTEM_PROMPT,
          },
          {
            role: 'user',
            name: 'user',
            content: userMessage,
          },
        ],
      };
      let token: string | undefined = undefined;
      token = await Firebase.auth.default.currentUser?.getIdToken(true);
      // return the headers to the context so httpLink can read them
      const headers = {
        authorization: token ? `Bearer ${token}` : undefined,
      };
      const result = await axios.post(API_URL, body, { headers: headers });
      setLoading(false);
      return result.data?.choices[0].message.content;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return { generateChat, loading };
};

enum MODE {
  RAW,
  GENAI,
}

export default function TranscribeContainer(props: TranscribeContainerProps) {
  const { generateChat, loading } = useChat();
  const [mode, setMode] = useState(
    (props.transcribedData ?? []).length > 0 ? MODE.GENAI : MODE.RAW,
  );
  const [highlights, setHighlights] = useState([]);
  const handleClick = () => {
    generateChat(props.jobRole, null, props.question, props.transcribedText).then((message) => {
      // substring message from first index of '[' to last index of ']'
      console.log(message);
      const jsonportion = message.substring(message.indexOf('['), message.lastIndexOf(']') + 1);
      console.log(jsonportion);
      const data = JSON.parse(jsonportion);
      console.log(data);
      setHighlights(data);
    });
  };
  const handleClick2 = () => {
    if (mode === MODE.RAW && props.transcribedData && props.transcribedData.length > 0) {
      setMode(MODE.GENAI);
    } else {
      setMode(MODE.RAW);
    }
  };
  if (!props.transcribedText) {
    return (
      <div tw="flex flex-col">
        <div tw="flex flex-row justify-between items-baseline mb-2">
          <div tw="font-bold text-2xl">Transcribed Text (beta)</div>
        </div>
        <div tw="border border-gray-200 rounded-2xl text-xl font-sans w-full h-full p-4 overflow-scroll flex flex-col gap-3">
          <HighlightContainer text={'No transcribed text'} highlights={[]} />
        </div>
      </div>
    );
  }

  const transcribedText =
    mode === MODE.RAW
      ? props.transcribedText
      : (props.transcribedData ?? []).filter(data => data.text).map((data) => data.text).join(' ');
  const highlightList =
    mode === MODE.RAW
      ? []
      : (props.transcribedData ?? [])
          .filter((data) => data.highlight && data.keyword)
          .map((data) => {
            return {
              sentence: data.keyword!,
              reason: data.reason!,
            };
          });

  return (
    <div tw="flex flex-col">
      <div tw="flex flex-row justify-between items-baseline mb-2">
        <div tw="font-bold text-2xl">Transcribed Text (beta)</div>
        {loading && (
          <Loader tw="mr-2 animate-spin motion-reduce:animate-[spin_1.5s_linear_infinite]" />
        )}
        {!loading && (
          <Button
            variant={mode === MODE.RAW ? 'info' : 'primary'}
            onClick={handleClick2}
            disabled={loading}
            tw="flex flex-row items-center"
          >
            <WandSparkles tw="mr-2" size={'1.2em'} />
            {'Find Insight'}
          </Button>
        )}
      </div>
      <div tw="border border-gray-200 rounded-2xl text-lg font-sans w-full p-4 overflow-scroll flex flex-col gap-3 h-full lg:max-h-80vh max-h-50vh">
        <HighlightContainer text={transcribedText} highlights={highlightList} />
      </div>
    </div>
  );
}
